@import "src/assets/scss/theme.scss";

.container {
  width: 75vw;
  min-width: 323px;

  @media (min-width: $breakpoint-sm) {
    width: 450px;
  }
}

.title {
  font-size: 24px;
}

.backArrow {
  font-size: 14px;
}
