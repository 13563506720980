@import "src/assets/scss/theme.scss";

.container {
  width: 17.5rem;
}

.data {
  display: flex;
  justify-content: space-between;

  &_bold {
    font-weight: 600;
  }
}

.datum {
  &_negative {
    color: $red-700;
  }
}
