@import "src/assets/scss/theme.scss";

.container {
  display: flex;
  justify-content: space-between;
}

.label {
  @include h400;
  font-size: 14px;
}
