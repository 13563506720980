@import "src/assets/scss/theme.scss";

.contentItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 4px;

  @media (min-width: $breakpoint-sm) {
    margin-top: 16px;
    margin-bottom: 4px;
  }

  &:last-of-type {
    margin-top: 16px;
  }
}

.title {
  @include text-600;
  font-size: 14px;
  color: $gray-600;
  margin: 0px;
}

.content {
  @include text-500;
  font-size: 14px;
  color: $gray-600;
  margin: 0px;

  &_flash {
    @include flashIn(1250ms);
  }
}
